import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { getImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';
import useWindowSize from '../hooks/useWindowSize';
import '../style/services.sass';
import PageIntroSection from '../components/PageIntroSection';
import BusinessImpact from '../components/BusinessImpact';
import ProductHighlights from '../components/ProductHighlights';
import UseGoToSection from '../components/UseGoToSection';

// Main Page Component
const SubServicesPageTemplate = ({ pageIntroData, productFeatures, businessImpact}) => (
  <div className="services-page">
    <PageIntroSection pageIntro={pageIntroData} />
    <ProductHighlights productFeatures={productFeatures}/>
    <BusinessImpact impact={businessImpact}/>
    <UseGoToSection />
  </div>
);


const SubServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
       <SubServicesPageTemplate 
      pageIntroData={frontmatter.subServices.customize.pageIntroData}
      productFeatures={frontmatter.subServices.productFeatures}
      businessImpact={frontmatter.subServices.businessImpact} 
      />
    </Layout>
  );
};

SubServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default SubServicesPage;

export const pageQuery = graphql`
  query SubServicesPage {
    markdownRemark(frontmatter: { templateKey: { eq: "custom-application" } }) {
      frontmatter {
        subServices {
          customize {
            pageIntroData {
              mobile {
                title {
                  fragment
                  color
                }
                backgroundImage {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                description
                buttonLabel
                buttonColor
                buttonPath
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  descriptionFontWeight
                  descriptionLineHeight
                  contentWidth
                  contentPadding
                }
              }
              desktop {
                title {
                  fragment
                  color
                }
                backgroundImage {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                backgroundVideo
                description
                buttonLabel
                buttonColor
                buttonPath
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  contentWidth
                  contentPadding
                  buttonPadding
                }
              }
            }
          }
          productFeatures {
            contentSection {
              title {
                fragment
                color
              }
              description
              image1 {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
              image2 {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
            title {
              fragment
              color
            }
            productFeaturesList {
              icon {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
              title
              description
            }
          }
          businessImpact {
            title {
              fragment
              color
            }
            description
            hexagonal {
              title
              subDescription
              logo {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
              color
              height
              width
              subWidth
              subHeight
              flexDirection
              textAlign
              position
              bottom
              right
              top
              left
              marginTop
              marginLeft
              marginRight
              marginBottom
              posRight
              posLeft
            }
            hexagonalImg {
              childImageSharp {
                    gatsbyImageData(
                      quality: 100, 
                      height: 95,
                      layout: CONSTRAINED,
                      placeholder: BLURRED,
                      formats: [AUTO, WEBP, AVIF],
                      breakpoints: [320, 480, 768, 1024, 1366, 1600 , 1800])
              }
            }
            position
            hexagonalColor
            fontFamily
            coreValuesList {
              icon {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              color
              title
              description
            }
          }
        }
      }
    }
  }
`;
